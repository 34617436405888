import React, { useState } from 'react';
import * as S from './styles';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import BountyCard from '../../assets/BountyCardCarrousel.png';
import BountyManagement from '../../assets/BountyManagementCarrousel.png';
import BountyNotification from '../../assets/BountyNotificationCarrousel.png';
import BountyLogo from '../../assets/BountyControlWhite.svg';
import BountyResponsiveImage from '../../assets/ModelSignInFemale.png';
import { Carousel } from '../../components/ui/Carrosel/Carousel';
import { FormProvider, useForm } from 'react-hook-form';
import CompanyForm, {
	CompanyFormSchemaPreRegister,
} from './Forms/CompanyForm/index';
import { Button } from '../../components/ui/Button';
import ContactForm, { ContactFormSchema } from './Forms/ContactForm';
import Success from '../../components/Success';
import { PreRegisterType, UtmParams } from '../../@types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from 'react-query';
import { CreatePreRegister } from '../../services/queries/PreRegister';
import { parseMaskedCnpjToRaw, parseMaskedPhoneToRaw } from '../../utils/masks';
import ErrorModal from '../../components/ui/ErrorModal';
import { errorToMessage } from '../../utils/errorMessages';
import { useLocation } from 'react-router-dom';
import { getUtmParams } from '../../utils/analytics';


const INITIAL_DATA: PreRegisterType = {
	document: '',
	legal_name: '',
	business_type: '',
	number_of_collaborators: '',
	contact: {
		full_name: '',
		email: '',
		phone: '',
		position_in_the_company: '',
	},
	utm:  {} as UtmParams
};

const CarouselImages = [
	{
		id: 1,
		image: BountyCard,
		title: 'BountyCard',
		description:
			'Aproveite a nossa plataforma por R$ 199,00 mensais ou tenha a mensalidade grátis para gastos a partir de R$ 20.000,00 por mês.',
	},
	{
		id: 2,
		image: BountyManagement,
		title: 'BountyManagement',
		description:
			'Gerencie todos os gastos realizados nos cartões corporativos em tempo real na mesma plataforma e garanta o controle financeiro.',
	},
	{
		id: 3,
		image: BountyNotification,
		title: 'BountyNotification',
		description:
			'Dê adeus ao processo de registro de despesas com a nossa conciliação automática e receba relatórios de uso detalhados.',
	},
];

const Information = [
	{
		title: 'Dados da empresa',
		description: 'Preencha os dados abaixo para iniciar o seu cadastro.',
		form: <CompanyForm />,
	},
	{
		title: 'Dados do contato',
		description: null,
		form: <ContactForm />,
	},
];

const schemas = [CompanyFormSchemaPreRegister, ContactFormSchema];

export default function PreRegister() {
	const [currentStep, setCurrentStep] = useState(0);
	const isLastStep = currentStep === 1;
	const [formData, setFormData] = useState({});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const form = useForm({
		resolver: zodResolver(schemas[currentStep]),
		defaultValues: INITIAL_DATA,
	});
	const { search } = useLocation();
	const [errorMessage, setErrorMessage] = useState<{title: string, message: string} | null>(null);

	const { handleSubmit } = form;

	const createPreRegiserMutation = useMutation(
		(data: PreRegisterType) => CreatePreRegister(data),
		{
			onSuccess: () => {
				setFormSubmitted(true);
			},
			onError: (error) => {
				setErrorMessage(errorToMessage(error as Error, {
					title: 'Erro ao criar pré-cadastro',
					message: 'Não foi possível criar o pré-cadastro, tente novamente mais tarde.'})
				);
			},
		}
	);

	const onSubmit = (data: PreRegisterType) => {
		const finalData = { ...formData, ...data };
		finalData.document = parseMaskedCnpjToRaw(finalData.document);
		finalData.contact.phone = parseMaskedPhoneToRaw("+55" + finalData.contact.phone);
		finalData.utm = getUtmParams(new URLSearchParams(search)) as UtmParams
		if (Object.keys(finalData.utm).length === 0) {
			finalData.utm = undefined;
		}
		createPreRegiserMutation.mutate(finalData);
	};

	const next = async () => {
		if (await form.trigger()) {
			const currentFormData = form.getValues();
			setFormData((prev) => ({ ...prev, ...currentFormData }));
			setCurrentStep((current) => current + 1);
		}
	};

	const back = () => setCurrentStep((current) => current - 1);

	return (
		<FormProvider {...form}>
			<ErrorModal errorMessage={errorMessage} resetMessage={() => setErrorMessage(null)} />
			<S.Container>
				<img src={BountyLogo} alt='Bounty Logo' />
				<CorporateExpensesTheme />
				<S.LeftContainer>
					<img
						src={BountyResponsiveImage}
						alt='Responsive Model'
						className='ResponsiveModelImage'
					/>
					<div className='desktopCarousel'>
						<Carousel
							interval={8000}
							currentIndex={CarouselImages.findIndex((index) => index.id)}
							content={CarouselImages.map((content) => (
								<S.AccessCard key={content.id}>
									<img src={content.image} alt={content.title} />
									<p>{content.description}</p>
								</S.AccessCard>
							))}
						/>
					</div>
				</S.LeftContainer>

				<S.RightContainer>
					<S.MainContainer>
						{formSubmitted ? (
							<Success
								title='Seus dados foram recebidos'
								message='Entraremos em contato pelo e-mail cadastrado em'
								messageTime=' até um dia útil.'
							/>
						) : (
							<>
								{Information[currentStep].description && (
									<S.Description>
										{Information[currentStep].description}
									</S.Description>
								)}
								<S.Header>
									<h2>{Information[currentStep].title}</h2>
									{currentStep < 2 && (
										<>
											<p>Todos os campos são obrigatórios</p>
											<div className='progress-bar'>
												<div
													className={`progress-step step-${currentStep + 1}`}
												/>
											</div>
										</>
									)}
								</S.Header>
								<form onSubmit={handleSubmit(onSubmit)}>
									{Information[currentStep].form}

									<S.BottomOptionsContainer>
										{currentStep > 0 && (
											<Button
												$outline
												intent='terciary'
												roundness='lg'
												onClick={back}
											>
												Voltar
											</Button>
										)}
										{!isLastStep ? (
											<Button onClick={next} roundness='lg'>
												Próximo
											</Button>
										) : (
											<Button
												roundness='lg'
												type='submit'
												disabled={createPreRegiserMutation.isLoading}
											>
												Concluir
											</Button>
										)}
									</S.BottomOptionsContainer>
									{currentStep === 1 && (
										<S.SupportMessage>
											Ao clicar em {<span>Concluir</span>}, você confirma que os
											dados são verdadeiros e podem ser verificados.
										</S.SupportMessage>
									)}
								</form>
							</>
						)}
					</S.MainContainer>
				</S.RightContainer>
			</S.Container>
		</FormProvider>
	);
}
