import { FormProvider, useForm } from 'react-hook-form';
import CompanyForm, { CompanyFormSchema } from './Forms/CompanyForm';
import React, { useState } from 'react';
import * as S from './styles';
import { Button } from '../../components/ui/Button';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import BountyLogoIcon from '../../assets/LogoIcon.png';
import LegalRepresentativeForm, {
	LegalRepresentativeSchema,
} from './Forms/LegalRepresentativeForm';
import AdminUserData, { AdminUserDataSchema } from './Forms/AdminDataUserForm';
import { MdCheckCircle } from 'react-icons/md';
import { FaClipboardList } from 'react-icons/fa';
import { zodResolver } from '@hookform/resolvers/zod';
import { RegisterType, UtmParams } from '../../@types';
import CheckListCard from './components/CheckListCard';
import { TitleTypography } from '../../components/ui/Typography';
import {
	createRegister,
	getCompanyRegister,
	saveFile,
} from '../../services/queries/Register';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import {
	cnpjMask,
	parseInputToDate,
	parseMaskedCEPToRaw,
	parseMaskedCnpjToRaw,
	parseMaskedCPFToRaw, parseMaskedPhoneToRaw,
} from '../../utils/masks';
import { errorToMessage } from '../../utils/errorMessages';
import ErrorModal from '../../components/ui/ErrorModal';
import { getUtmParams } from '../../utils/analytics';

const INITIAL_DATA: RegisterType = {
	address: {
		city: '',
		complement: '',
		neighborhood: '',
		number: '',
		postal_code: '',
		reference: '',
		state: '',
		street: '',
	},
	phone_number: '',
	shareholders: [],
	social_contract_file: null,
	website: '',
	name_admin: '',
	email_admin: '',
	confirm_email_admin: '',
	email: '',
	utm: {} as UtmParams
};

const Information = [
	{
		title: { primary: 'Endereço', text: 'da empresa' },
		description:
			'Nesta etapa, precisaremos de algumas informações para fins cadastrais, como endereço da sede da empresa e telefone para contato.',
		form: <CompanyForm />,
	},
	{
		title: { primary: 'Quadro', text: 'societário' },
		description:
			'Nesta etapa, você irá cadastrar todos os sócios que fazem parte da empresa para validar os dados.',
		form: <LegalRepresentativeForm />,
	},
	{
		title: { primary: 'Login', text: 'do administrador' },
		description:
			'Preencha os dados da pessoa que ficará responsável pelo Bounty Control e terá acesso a todas as funcionalidades da plataforma.',
		form: <AdminUserData />,
	},
];

const schemas = [
	CompanyFormSchema,
	LegalRepresentativeSchema,
	AdminUserDataSchema,
];

export default function Register() {
	const [currentStep, setCurrentStep] = useState(0);
	const isLastStep = currentStep === 3;
	const methods = useForm({
		resolver: zodResolver(schemas[currentStep - 1]),
		defaultValues: INITIAL_DATA,
	});
	const [formData, setFormData] = useState({});
	const { handleSubmit, watch } = methods;
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<{title: string, message: string} | null>(null);
	const { search } = useLocation();


	const { id } = useParams<{ id: string }>();

	const getCompanyQuery = useQuery<any, Error>(
		[id],
		() => {
			return getCompanyRegister(id);
		},
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchInterval: false
		}
	);

	const createRegisterMutation = useMutation(
		(data: FormData) => createRegister(id, data),
		{
			onError: (error) => {
				setErrorMessage(errorToMessage(error as Error, {
					title: 'Erro ao enviar o cadastro',
					message: 'Houve um erro ao enviar o cadastro, tente novamente.'
				}))
				setIsLoading(false)
			},
		},
	);

	const saveFileMutation = useMutation(
		(data: FormData) => saveFile(id, data),
		{
			onError: (error) => {
				setErrorMessage(errorToMessage(error as Error, {
					title: 'Erro ao enviar um arquivo',
					message: 'Houve um erro ao enviar o arquivo, tente novamente.'
				}))
				setIsLoading(false)
			},
		},
	);

	const next = async () => {
		if (await methods.trigger()) {
			const currentFormData = methods.getValues();
			setFormData((prev) => ({ ...prev, ...currentFormData }));
			setCurrentStep(currentStep + 1);
		} else {
			console.log('Erro de validação na etapa ' + currentStep);
		}
	};

	const onSubmit = async (data: RegisterType) => {
		setIsLoading(true);
		const finalData = { ...formData, ...data };
		finalData.utm = getUtmParams(new URLSearchParams(search)) as UtmParams;

		const registerForm = new FormData();

		const fileForms: FormData[] = [];

		finalData.shareholders.forEach((shareholder, index) => {
			const shareholderFileForm = new FormData();

			if (shareholder.social_contract_file){
				// Shareholder file data
				shareholderFileForm.append(
					`social_contract_file`,
					shareholder.social_contract_file,
				);

				shareholderFileForm.append(
					shareholder.type === 'Pessoa Jurídica' ? 'cnpj' : 'cpf',
					shareholder.type === 'Pessoa Jurídica' ? parseMaskedCnpjToRaw(shareholder.document)
						: parseMaskedCPFToRaw(shareholder.document)
				);

				fileForms.push(shareholderFileForm);
			}

			// Company shareholders data
			if (shareholder.type === 'Pessoa Jurídica') {
				// Shareholder PJ data
				registerForm.append(`shareholders[${index}][cnpj]`, parseMaskedCnpjToRaw(shareholder.document));
				registerForm.append(`shareholders[${index}][legal_name]`, shareholder.legal_name);
				registerForm.append(`shareholders[${index}][type_of_social_contract]`, 'PJ');
				registerForm.append(`shareholders[${index}][type]`, shareholder.user_type);
				registerForm.append(`shareholders[${index}][founding_date]`, parseInputToDate(shareholder.foundation_date));
			} else {
				// Shareholder PF data
				const name = shareholder.name.split(' ');
				const first_name = name.shift()!;
				const last_name = name.join(' ');
				registerForm.append(`shareholders[${index}][first_name]`, first_name);
				registerForm.append(`shareholders[${index}][last_name]`, last_name);
				registerForm.append(`shareholders[${index}][birth_date]`, parseInputToDate(shareholder.birth_date));
				registerForm.append(`shareholders[${index}][type_of_social_contract]`, 'PF');
				registerForm.append(`shareholders[${index}][cpf]`, parseMaskedCPFToRaw(shareholder.document));
				registerForm.append(`shareholders[${index}][type]`, shareholder.user_type);
			}
		});

		// Admin data
		registerForm.append('user_admin[full_name]',  finalData.name_admin);
		registerForm.append('user_admin[email]', finalData.email_admin);

		// Company data
		registerForm.append('social_contract_file', finalData.social_contract_file!);
		registerForm.append('phone_number', parseMaskedPhoneToRaw( "+55" + finalData.phone_number));
		registerForm.append('email', finalData.email_admin);
		registerForm.append('website', finalData.website);

		registerForm.append('address[city]', finalData.address.city);
		registerForm.append('address[state]', finalData.address.state);
		registerForm.append('address[neighborhood]', finalData.address.neighborhood);
		registerForm.append('address[street]', finalData.address.street);
		registerForm.append('address[number]', finalData.address.number);
		registerForm.append('address[complement]', finalData.address.complement);
		registerForm.append('address[reference]', finalData.address.reference);
		registerForm.append('address[postal_code]', parseMaskedCEPToRaw(finalData.address.postal_code));

		// UTM data
		Object.entries(finalData.utm).forEach(([key, value]) => {
			registerForm.append(`utm[${key}]`, value ?? "");
		})

		try {
			await createRegisterMutation.mutateAsync(registerForm);

			for (const form of fileForms) {
				await saveFileMutation.mutateAsync(form);
			}
		} catch (error) {
			setIsLoading(false)
		}

		setCurrentStep(4);
		setIsLoading(false)
	};

	const Welcome = () => {
		return (
			<S.Container>
				<CorporateExpensesTheme />
				<S.LeftContainer>
					<S.Box
						width="24rem"
						height="16.6rem"
						background="var(--primary-blue)"
					>
						<p style={{ fontSize: '2rem', lineHeight: '140%' }}>
							{' '}
							<strong>Falta pouco</strong> para concluir o seu cadastro
						</p>
					</S.Box>
					<S.Box
						width="24.9rem"
						height="13.3rem"
						background="var(--dark-gray2)"
					>
						<p style={{ fontSize: '1.6rem' }}>
							<strong>Ficou com dúvida?</strong>
							<a href="mailto:suporte@cartaobounty.com.br">
								Entre em contato conosco
							</a>
						</p>
					</S.Box>
				</S.LeftContainer>

				<S.RightContainer>
					<S.ScrollContainer>
						<S.MainContainer>
							<S.WelcomeHeader>
								<img src={BountyLogoIcon} width={120} height={48} alt="" />
								<h1>Seja bem-vindo!</h1>
								<p className="responsiveText">
									<span>Falta Pouco!</span> para concluir o seu cadastro
								</p>
							</S.WelcomeHeader>
							<S.WelcomeCheckList>
								<div
									style={{
										display: 'flex',
										columnGap: '1rem',
										alignItems: 'center',
									}}
								>
									<FaClipboardList size={21} color="var(--primary-blue)" />{' '}
									<h1>Checklist</h1>
								</div>
								<p>
									Estes são os <strong>documentos que precisaremos</strong> para
									dar continuidade ao seu cadastro.
								</p>
								<S.CheckListContent>
									<CheckListCard
										title="Contrato social"
										description="Precisaremos do documento que consta os dados da empresa e do quadro societário. Se a sua empresa possuir sócios PJ, precisaremos também do contrato social dessas empresas. "
									/>
									<CheckListCard
										title="Documento dos sócios"
										description="Para continuar, precisamos do RG ou CNH de quem consta no contrato social da empresa contratante. Se houver sócios PJ, envie também o RG do representante dessas empresas sócias."
									/>
								</S.CheckListContent>
							</S.WelcomeCheckList>
							<S.ButtomOptionContainer>
								<Button onClick={() => setCurrentStep(1)} roundness="lg">
									Começar cadastro
								</Button>
								<a className="responsiveText" href="mailto:suporte@cartaobounty.com.br">
									Entre em contato conosco
								</a>
							</S.ButtomOptionContainer>
						</S.MainContainer>
					</S.ScrollContainer>
				</S.RightContainer>
			</S.Container>
		);
	};

	const Thanks = () => {
		return (
			<S.Container>
				<CorporateExpensesTheme />
				<S.LeftContainer>
					<S.Box
						width="24rem"
						height="16.6rem"
						background="var(--primary-blue)"
					>
						<p style={{ fontSize: '2rem', lineHeight: '140%' }}>
							{' '}
							<strong>Falta pouco</strong> para concluir o seu cadastro
						</p>
					</S.Box>
					<S.Box
						width="24.9rem"
						height="13.3rem"
						background="var(--dark-gray2)"
					>
						<p style={{ fontSize: '1.6rem' }}>
							<strong>Ficou com dúvida?</strong>
							<a href="mailto:suporte@cartaobounty.com.br">
								Entre em contato conosco
							</a>
						</p>
					</S.Box>
				</S.LeftContainer>

				<S.RightContainer className="thanksContainer">
					<S.ScrollContainer>
						<S.ThanksContent>
							<MdCheckCircle color="var(--primary-blue)" size={48} />
							<div>
								<h1>Muito obrigado!</h1>
								<h3>
									Seus dados já foram <span>recebidos.</span>
								</h3>
							</div>
							<p>
								Analisaremos as informações e entraremos em contato assim que essa etapa for concluída.
							</p>
							<img src={BountyLogoIcon} width={120} height={48} alt="" />
						</S.ThanksContent>
					</S.ScrollContainer>
				</S.RightContainer>
			</S.Container>
		);
	};

	const shareholders = watch('shareholders');
	const social_contract_file = watch('social_contract_file');

	const GeneralInformation = () => {
		const formSteps = currentStep - 1;
		const currentForm = Information[formSteps]?.form;

		return (
			<S.GeneralContainer>
				<CorporateExpensesTheme />
				<S.TopContainer></S.TopContainer>
				<S.BottomContainer>
					<S.InformationContainer>
						<div>
							<TitleTypography
								size="3.2rem"
								primaryText={Information[formSteps].title.primary}
							>
								{' '}
								{Information[formSteps].title.text}
							</TitleTypography>
							<p>{Information[formSteps].description}</p>
						</div>
						<div>
							<div>
								<h3>Razão Social:</h3>
								<p>{getCompanyQuery.data?.legal_name}</p>
							</div>
							<div>
								<h3>CNPJ:</h3>
								<p>{cnpjMask(getCompanyQuery.data?.document)}</p>
							</div>
						</div>
					</S.InformationContainer>
					<S.FormsContainer onSubmit={handleSubmit(onSubmit)}>
						{currentForm}
						<S.BottomOptionsContainer>
							<S.DesktopContent>
								<Button
									type={'button'}
									style={{ height: '4.8rem' }}
									intent="terciary"
									$outline
									roundness="lg"
									onClick={() => setCurrentStep(currentStep - 1)}>
									Voltar
								</Button>
							</S.DesktopContent>
							<S.MobileContent>
								<Button
									type={'button'}
									intent="link"
									$outline
									roundness="lg"
									onClick={() => setCurrentStep(currentStep - 1)}>
									Voltar
								</Button>
							</S.MobileContent>
							{!isLastStep ? (
								<Button
									type={'button'}
									style={{ height: '4.8rem' }}
									onClick={next}
									disabled={currentStep === 2 && (shareholders.length === 0 || !social_contract_file)}
									roundness="lg"
								>
									Próximo passo
								</Button>
							) : (
								<Button
									style={{ height: '4.8rem' }}
									roundness="lg"
									loading={isLoading}
									type="submit"
								>
									Concluir
								</Button>
							)}
						</S.BottomOptionsContainer>
					</S.FormsContainer>
				</S.BottomContainer>
			</S.GeneralContainer>
		);
	};

	if (getCompanyQuery.isLoading ) {
		return (
			<h1>Carregando</h1>
		)
	}

	if (!getCompanyQuery.data) {
		return (
			<h1>404</h1>
		)
	}

	return (
		<FormProvider {...methods}>
			{currentStep === 0 && <Welcome />}
			{currentStep > 0 && currentStep < 4 && <GeneralInformation />}
			{currentStep === 4 && <Thanks />}
			<ErrorModal errorMessage={errorMessage} resetMessage={() => setErrorMessage(null)} />
		</FormProvider>
	);
}
